<template>
	<div class="customer-template-setting">
		<!-- <v-layout class="py-3 px-3">
			<v-flex class="">
				<div class="fw-600 sub-heading primary--text">Backlink Library</div>
			</v-flex>
			<v-spacer></v-spacer>
			<v-flex class="d-flex justify-content-end">
				<v-btn class="" dense tile v-on:click="goBack()">
					<v-icon class="ml-1">mdi-arrow-left</v-icon>
					Back
				</v-btn>
			</v-flex>
		</v-layout> -->

		<div class="d-flex mt-2">
			<div md="2" class="mr-2">
				<TextInput
					hide-details
					ref="search-custom-ref"
					style="margin-top: 0px !important"
					:disabled="pageLoading"
					:loading="pageLoading"
					id="search"
					clearable
					placeholder="Search"
					@click:clear="(backLinkSearch.search = null), getData()"
					v-model="backLinkSearch.search"
					@keyup="changeFilter('search-custom-ref')"
				></TextInput>
			</div>
			<div class="mr-2">
				<v-autocomplete
					:items="usersList"
					:menu-props="{ bottom: true, offsetY: true }"
					outlined
					:disabled="pageLoading"
					v-model="backLinkSearch.user_type"
					clearable
					@click:clear="(defaultFilter = {}), getListing()"
					style="max-width: 250px !important"
					hide-details
					item-text="display_name"
					item-value="id"
					placeholder="SEO Users"
					@input="changeFilter('user')"
				>
					<template v-slot:selection="data">
						<v-chip v-bind="data.attrs" :input-value="data.selected" @click="data.select">
							<v-avatar left>
								<v-avatar left v-if="data.item.profile_img">
									<v-img v-if="data.item.profile_img" :src="data.item.profile_img"></v-img>
									<v-img v-else :src="$assetURL('media/misc/no_photo_found.png')"></v-img>
								</v-avatar>
							</v-avatar>
							{{ data.item.display_name }}
						</v-chip>
					</template>
					<template #item="{ item }">
						<div class="py-1 d-flex align-center">
							<v-avatar size="36" class="mr-3">
								<img v-if="item.profile_img" :src="item.profile_img" />
								<v-img v-else :src="$assetURL('media/misc/no_photo_found.png')"></v-img>
							</v-avatar>
							<div>
								<div class="text-uppercase fw-500">{{ item.display_name }}</div>
								<div class="text-muted">{{ item.email }}</div>
							</div>
						</div>
					</template>
				</v-autocomplete>
			</div>
			<div class="mr-2">
				<DateRangePicker
					style="width: 250px"
					hide-details
					hide-top-margin
					clearable
					@click:clear="(backLinkSearch.filter_date = null), changeFilter('date')"
					v-model="backLinkSearch.filter_date"
					@change="
						(backLinkSearch.filter_date.length > 1 || backLinkSearch.filter_date == null) &&
							changeFilter('date')
					"
				></DateRangePicker>
			</div>
			<div class="mr-2">
				<SelectInput
					:items.sync="seoCategoryList"
					id="category"
					clearable
					style="margin-top: 0px !important; width: 150px"
					hide-details
					name="text"
					itemValue="value"
					itemText="title"
					placeholder="Category"
					v-model="backLinkSearch.category"
					@change="changeFilter('category')"
					:menu-props="{ offsetY: true }"
					no-style-item="title"
				></SelectInput>
			</div>
			<div class="mr-2">
				<v-select
					outlined
					:items.sync="countryList"
					id="country"
					clearable
					style="margin-top: 0px !important"
					hide-details
					placeholder="Country"
					item-text="country"
					item-value="country"
					v-model="newSite.country"
					@change="changeFilter('country')"
					:menu-props="{ offsetY: true }"
				>
					<template #item="{ item }">
						<v-list>
							<v-list-item class="d-flex justify-center align-center">
								<v-list-item-icon style="margin: 0 !important">
									<v-icon
										class="mt-1 mr-2"
										v-if="item?.country?.toLowerCase() == 'global'"
										color="blue darken-3"
									>
										mdi-earth
									</v-icon>
									<v-icon v-else :class="getFlag(item.country)" class="vti__flag mr-2 mt-2" />
								</v-list-item-icon>
								<v-list-item-content>
									<v-list-item-title class="d-flex justify-space-between">
										{{ item.country }}
										<div>
											<v-chip outlined label color="cyan" small>
												{{ item.total }}
											</v-chip>
										</div>
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</v-list>
					</template>
				</v-select>
			</div>
			<div>
				<SelectInput
					:items.sync="seoActivityList"
					clearable
					id="activity"
					style="margin-top: 0px !important; width: 150px"
					hide-details
					name="text"
					item-value="value"
					itemText="title"
					placeholder="Activity"
					v-model="backLinkSearch.activity"
					@change="changeFilter('activity')"
					:menu-props="{ offsetY: true }"
					no-style-item="title"
				></SelectInput>
			</div>
		</div>
		<!-- <div class="border pb-3 mb-5"> -->
		<!-- <v-layout class="py-2 px-4 blue lighten-5">
				<v-flex class=""> itemText="title"
					<div class="fw-600 sub-heading primary--text text-capitalize">Backlink Library</div>
				</v-flex>
				<v-spacer></v-spacer>
				<v-flex class="d-flex justify-content-end">
					<v-btn
						class="mx-4 white--text"
						dense
						tile
						color="blue darken-4"
						v-on:click="createBacklibrary()"
					>
						Create
					</v-btn>
					<v-btn class="white--text" dense tile color="blue darken-4"> Import </v-btn>
				</v-flex>
			</v-layout> -->

		<div :class="[{ 'mt-4': !pageLoading, 'mt-2': pageLoading }]" class="mb-3 backlink-add-tbl">
			<div v-if="pageLoading">
				<v-progress-linear indeterminate color="orange" height="8"></v-progress-linear>
			</div>
			<v-simple-table class="bt-table">
				<thead>
					<tr>
						<th
							v-for="(heading, index) in backLink_table_heading"
							:key="`_heading_${index}`"
							class="p-2 custome-table"
							style="background: #fff !important"
						>
							<template v-if="heading.t_head">
								{{ heading.t_head }}
							</template>
							<template v-else>
								<v-checkbox
									:ripple="false"
									color="#0D47A1"
									v-model="SelectBacklinkRow"
									hide-details
									dense
								></v-checkbox>
							</template>
						</th>
					</tr>
				</thead>

				<tbody v-if="seoBackLinkLibrary.length">
					<tr v-for="(row, index) in seoBackLinkLibrary" :key="index">
						<td class="p-2 border-top-light-grey custome-table-td">
							<v-checkbox
								:ripple="false"
								color="#0D47A1"
								v-model="selectBacklinkRow"
								:value="row.id"
								multiple
								hide-details
								dense
							></v-checkbox>
						</td>
						<td class="p-2 border-top-light-greycustome-table-td">
							<div class="text-truncate">
								<ShowValue
									:object="row"
									objectKey="domain"
									:capitalize="false"
									clickToCopy
									customClass="fw-600 fs-14"
									label="domain"
								></ShowValue>
							</div>
						</td>
						<td class="p-2 border-top-light-grey custome-table-td">
							<div class="fw-500 text-center fw-12">{{ row.total ? row.total : "-" }}</div>
						</td>

						<td class="p-2 border-top-light-grey custome-table-td">
							<v-chip label outlined color="green">{{
								seoCategoryList.find((v) => v.value == row.category)?.title
							}}</v-chip>
						</td>
						<td class="p-2 border-top-light-grey custome-table-td">
							<v-chip label outlined color="purple">{{
								seoActivityList.find((v) => v.value == row.activity)?.title
							}}</v-chip>
						</td>
						<td class="p-2 border-top-light-grey custome-table-td">
							<div class="fw-500 text-center fw-12">{{ row.spam_score ? row.spam_score : "-" }}</div>
						</td>
						<td class="p-2 border-top-light-grey custome-table-td">
							<v-chip label outlined color="red">{{ row.country }}</v-chip>
						</td>
						<td class="p-2 border-top-light-grey custome-table-td">
							<div class="fw-500 text-capitalize">
								{{ row.added_by.display_name }}
							</div>
							<div>
								<v-chip outlined label color="green" class="mt-1" x-small>
									{{ row.added_at }}
								</v-chip>
							</div>
						</td>
					</tr>
				</tbody>
				<tfoot v-else>
					<tr v-if="!seoBackLinkLibrary.length && !pageLoading">
						<td colspan="8">
							<p class="m-0 row-not-found text-center">
								<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
								Uhh... There are no backlink at the moment.
							</p>
						</td>
					</tr>
				</tfoot>
			</v-simple-table>
		</div>
		<div v-if="!totalPage" style="height: 45px"></div>
		<v-row v-else>
			<v-col md="6" class="my-auto">
				<p class="m-0">{{ showingString }}</p>
			</v-col>
			<v-col md="6" class="text-right">
				<v-pagination
					color="blue darken-4"
					total-visible="9"
					v-model="page"
					:length="totalPage"
					@input="updatePagination($event)"
				></v-pagination>
			</v-col>
		</v-row>
		<!-- </div> -->
		<!-- <CreateBacklLink @close="createBackLinkDialog = false" :dialog="createBackLinkDialog" /> -->
	</div>
</template>
<style src="@/assets/sass/sprite.scss" lang="scss"></style>
<script>
import { formatDistanceToNow } from "date-fns";
import { parseISO } from "date-fns";
import { QUERY } from "@/core/services/store/request.module";
// import { SET_MESSAGE } from "@/core/services/store/common.module";
// import Dialog from "@/view/components/Dialog";
import TextInput from "@/view/components/TextInput";
import SelectInput from "@/view/components/SelectInput";
import DateRangePicker from "@/view/components/DateRangePicker";
import ShowValue from "@/view/components/ShowValue";
import { cloneDeep, xor } from "lodash";

// import CreateBacklLink from "@/view/components/CreateBacklink";
export default {
	name: "BacklinkLibarary",
	props: {
		seoActivityList: {
			type: Array,
			default: () => [],
		},
		seoCategoryList: {
			type: Array,
			default: () => [],
		},
		projectId: {
			type: [Number, String],
			default: null,
		},
		seoId: {
			type: [Number, String],
			default: null,
		},
		usersList: {
			type: Array,
			default: () => [],
		},
	},
	components: {
		// Dialog,
		SelectInput,
		TextInput,
		// CreateBacklLink,
		DateRangePicker,
		ShowValue,
	},
	data() {
		return {
			seoBackLinkLibrary: [],
			selectBacklinkRow: [],
			zoom: {
				client_id: null,
				client_secret: null,
			},
			createBackLinkDialog: false,
			newSite: {
				domain: null,
				category: null,
			},
			selectedBacklink: [],
			categoryList: [
				{ value: "wedding_decoration", text: "Wedding Decoration" },
				{ value: "furniture", text: "Furniture" },
				{ value: "office_furniture", text: "Office Furniture" },
				{ value: "logistics", text: "Logistics" },
				{ value: "pest_control", text: "Pest Control" },
				{ value: "cleaning", text: "Cleaning" },
				{ value: "industrial_equipment", text: "Industrial Equipment" },
				{ value: "massage", text: "Massage" },
				{ value: "game", text: "Game" },
				{ value: "computer_repair", text: "Computer Repair" },
				{ value: "trading", text: "Trading" },
				{ value: "lawyer", text: "Lawyer" },
				{ value: "machines_&_tools", text: "Machines & Tools" },
				{ value: "garden_&_environment", text: "Garden & Environment" },
				{ value: "door", text: "Door" },
				{ value: "marine_accessories_&_valve", text: "Marine Accessories & valve" },
				{ value: "water_filter_&_dispenser", text: "Water filter & Dispenser" },
				{ value: "electrician_&_maintenance", text: "Electrician & maintenance" },
				{ value: "food", text: "Food" },
				{ value: "beauty_products_&_services", text: "Beauty products & services" },
				{ value: "plumbing_services", text: "Plumbing services" },
				{ value: "aircon", text: "Aircon" },
				{ value: "software", text: "Software services" },
				{ value: "photography", text: "Photography" },
				{ value: "florist", text: "Florist" },
				{ value: "flooring", text: "Flooring" },
				{ value: "renovation_&_designer", text: "Renovation & Designer" },
				{ value: "security", text: "Security" },
				{ value: "health_&_medical", text: "Health & Medical" },
				{ value: "corporate_gifts", text: "Corporate Gifts" },
				{ value: "funeral", text: "Funeral" },
				{ value: "education_&_training", text: "Education & Training" },
				{ value: "marketing_services", text: "Marketing Services" },
				{ value: "automobile", text: "Automobile" },
				{ value: "garments", text: "Garments" },
				{ value: "event_management", text: "Event Management" },
				{ value: "accounting_&_financial_services", text: "Accounting & Financial Services" },
				{ value: "digital_lock", text: "Digital Lock" },
				{ value: "agriculture", text: "Agriculture" },
				{ value: "homeware_products", text: "Homeware products" },
				{ value: "real_estate", text: "Real estate" },
				{ value: "construction", text: "Construction" },
				{ value: "consultancy_recruitment", text: "Consultancy/ Recruitment" },
				{ value: "tour_travel", text: "Tour/Travel" },
				{ value: "charity_organisation", text: "Charity Organisation" },
				{ value: "miscellaneous", text: "Miscellaneous" },
				{ value: "other", text: "Other" },
			],
			backLinkSearch: {
				search: "",
				user_type: null,
				category: "",
				filter_date: [],
				activity: "",
			},
			backLink_table_heading: [
				{ t_head: null, b_color: "#f0f8ff00", width: "100" },
				{ t_head: "DOMAIN", b_color: "#f0f8ff00" },
				{ t_head: "DOMAIN USED", b_color: "#f0f8ff00" },
				{ t_head: "CATEGORY", b_color: "#f0f8ff00" },
				{ t_head: "ACTIVITY", b_color: "#f0f8ff00" },
				{ t_head: "SPAM SCORE", b_color: "#f0f8ff00" },
				{ t_head: "COUNTRY", b_color: "#f0f8ff00" },
				{ t_head: "ADDED BY", b_color: "#f0f8ff00" },
			],

			backLink_library: [
				{
					domain: "https://globaledu.com.sg",
					category: "Editorial",
					activity: "Business",
					country: "Singapore",
					added_by: "Sagar Kumar",
				},
				{
					domain: "https://globaledu.com.sg",
					category: "Editorial",
					activity: "Article",
					country: "Singapore",
					added_by: "Sagar Kumar",
				},
				{
					domain: "https://globaledu.com.sg",
					category: "Editorial",
					activity: "Syndication",
					country: "Singapore",
					added_by: "Sagar Kumar",
				},
			],
			pageLoading: false,
			activityList: [
				{ value: "business_listing", text: "Business Listing" },
				{ value: "guest_post", text: "Guest Post" },
				{ value: "profile_creation", text: "Profile Creation" },
				{ value: "social_bookmarking", text: "Social Bookmarking" },
				{ value: "article_sbn", text: "Article SBN" },
				{ value: "blog_sbn", text: "Blog SBN" },
				{ value: "classified_sbn", text: "Classified SBN" },
				{ value: "ppt_sbn", text: "PPT SBN" },
				{ value: "infographics_sbn", text: "Infographics SBN" },
				{ value: "pdf_sbn", text: "PDF SBN" },
				{ value: "blog_commenting", text: "Blog Commenting" },
				{ value: "qa_quora", text: "QA/Quora" },
				{ value: "image_sbn", text: "Image SBN" },
				{ value: "video_sbn", text: "Video SBN" },
				{ value: "microblogging-Web_2.0", text: "Microblogging/Web 2.0" },
				{ value: "syndication", text: "Syndication" },
				// { value: "blog_syndication", text: "Blog Syndication" },
				// { value: "article_syndication", text: "Article Syndication" },
				// { value: "ppt_syndication", text: "PPT Syndication" },
				// { value: "web_blog_syndication", text: "Web blog Syndication" },
			],
			countryList: [],
			countryListWithFlag: [
				{
					text: "Singapore",
					value: "singapore",
					flag: "sg",
				},
				{
					text: "Malaysia",
					value: "malaysia",
					flag: "my",
				},
				{
					text: "United State",
					value: "united_state",
					flag: "us",
				},
				{
					text: "Australia",
					value: "australia",
					flag: "au",
				},
				{
					text: "Nigeria",
					value: "nigeria",
					flag: "ng",
				},
				{
					text: "Vietnam",
					value: "vietnam",
					flag: "vn",
				},
				{
					text: "Thailand",
					value: "thailand",
					flag: "th",
				},
				{
					text: "Global",
					value: "global",
					flag: "global",
				},
			],
			timer: null,
			showingString: null,
			page: 1,
			totalPage: 1,
			dataIds: [],
		};
	},
	watch: {
		selectBacklinkRow: {
			deep: true,
			handler(value) {
				this.$emit("selectBacklinkRow", value);
			},
		},
	},
	methods: {
		formatDate(dateString) {
			const date = parseISO(dateString);
			return formatDistanceToNow(date, { addSuffix: true });
		},
		updatePagination(/* param */) {
			/* if (this.page == param) {
				return false;
			} */
			this.$nextTick(() => {
				// this.$emit("reload:content", true);
				this.getData();
				// this.allFiltersStatus();
				// this.statusFilterRows();
			});
		},
		getFlag(country) {
			if (country) {
				const _country = country.toLowerCase();
				const _countryText = country.toLowerCase().replaceAll(" ", "_");
				let cc = this.countryListWithFlag.find((v) => v.value == _country || v.value == _countryText);
				return (cc && cc?.flag) || "";
			}
		},
		goBack() {
			this.$router.go(-1);
		},
		createBacklibrary() {
			this.createBackLinkDialog = true;
		},

		getData(page = this.page || 1, type = null) {
			let payload = {
				search: this.backLinkSearch.search,
				page: page,
				country: this.newSite.country,
				category: this.backLinkSearch.category,
				activity: this.backLinkSearch.activity,
				daterange: this.backLinkSearch.filter_date,
				added_by: this.backLinkSearch.user_type,
				project_id: this.projectId,
				seo_id: this.seoId,
			};

			this.pageLoading = true;

			this.$store
				.dispatch(QUERY, {
					url: "list-backlink",
					data: payload,
				})
				.then((data) => {
					if (data && Object.keys(data).length) {
						if (Array.isArray(data.data) && data.data.length) {
							this.seoBackLinkLibrary = data.data;
							this.dataIds = cloneDeep(this.seoBackLinkLibrary.map((r) => r.id));
						} else {
							this.seoBackLinkLibrary = [];
							this.dataIds = [];
						}
						this.showingString = data.showing_string;
						this.page = data.current_page;
						this.totalPage = data.total_pages;
						if (Array.isArray(data.total_count) && data.total_count.length) {
							this.countryList = cloneDeep(data.total_count);
						}
					}
					if (type) {
						this.$refs[type].focus();
					}
					this.selectBacklinkRow = cloneDeep(this.selectBacklinkRow);
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		changeFilter(type) {
			if (type == "search-custom-ref") {
				clearTimeout(this.timer);
				this.timer = setTimeout(() => {
					this.getData(1, type);
				}, 600);
			} else {
				this.getData(1, type);
			}
			/* if (type == "activity") {
				this.getData();
			}

			if (type == "category") {
				this.getData();
			}

			if (type == "country") {
				this.getData();
			}

			if (type == "date") {
				this.getData();
			}

			if (type == "user") {
				this.getData();
			} */
		},
	},
	computed: {
		// mod_activityList() {
		// 	return this.activityList.filter((v) => !v.value.includes("syndication"));
		// },
		SelectBacklinkRow: {
			get() {
				return (
					this.seoBackLinkLibrary.length &&
					this.selectBacklinkRow.length === this.seoBackLinkLibrary.length
				);
			},
			set(value) {
				if (value) {
					this.selectBacklinkRow = this.selectBacklinkRow.concat(cloneDeep(this.dataIds));
				} else {
					this.selectBacklinkRow = xor(this.selectBacklinkRow, this.dataIds);
				}
			},
		},
	},
	mounted() {
		this.getData();
	},
};
</script>
